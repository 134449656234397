import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Meststoffen() {
    document.title = "Grond- en meststoffen - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Grond- en meststoffen" />
        <Container>
        <div className="page-content">
                <Row>
                    <Col xs={12} md={12}>
                        <h2>POKON/ECOSTYLE Meststoffen</h2>
                        <p>
                            Naast goede grond hebben uw planten behoefte aan een goede voeding om gezond en sterk te blijven. Geef uw planten daarom naast water ook regelmatig een portie voeding. Het resultaat zal snel zichtbaar zijn. Groen blad wordt groener, de bloei wordt uitbundiger en plantenziekten krijgen minder kans. Wij hebben een heel groot assortiment voedingsstoffen, bodemverbeteraars enz. van de merken POKON en ECOSTYLE.
                            Maak uw tuin, terras of balkon extra sfeervol met onze buitenpotterie. Verkrijgbaar in heel veel soorten. Of u nu kiest voor kunststof, aardewerk of mandwerk, wij hebben een uitgebreid assortiment. Door de ruime keuze, vindt u altijd een stijl die bij u en uw tuin past.
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Meststoffen;